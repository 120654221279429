import React from "react";
import { Switch, Route } from "react-router-dom";
import { NewHomeScreen } from "./pages/NewHome/NewHomeScreen";
import { Footer } from "./pages/NewHome/Footer";
import { PrivacyScreen } from "./pages/Privacy/PrivacyScreen";
import { TermsScreen } from "./pages/Terms/TermsScreen";
import { MintScreen } from "./pages/Mint/MintScreen";


import { Providers } from "./providers";
import Wallet from "./web3/index"
import "./styles/reset.css";
import "./styles/style.css";

export function App() {

  return (
    <Providers>
      <Switch>
        <Route exact path="/" component={NewHomeScreen} />
        <Route exact path="/privacy" component={PrivacyScreen} />
        <Route exact path="/terms" component={TermsScreen} />
        <Route exact path="/mint" component={MintScreen} />
      </Switch>
      <Footer/>
    </Providers>
  );
}
