import React from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components'

import tycoLogo from "../../assets/1x/FOOTER/Asset 36.png";
import tycoCommunity from "../../assets/1x/FOOTER/Asset 35.png";

import './css/default.css';
import './css/normalize.css';
import './css/tyco.webflow.css';

export const Footer = () => {
  return (
        <div className="footer-wrap">
          <div className="div-block-8">
            <div className="div-block-11">
              <div>
                <LogoImage src={tycoLogo}/>
              </div>
            </div>
            <LinkDiv>

                <CommunityLogo src={tycoCommunity}/>
                <p className="section-text-footer">© 2022 Tyco Swagg<br/></p>
                <Links>
                  <NavLink to="/privacy" className="webflow-link w-inline-block">
                    <div className="paragraph-tiny">PRIVACY POLICY</div>
                  </NavLink>
                  <div className="paragraph-tiny"> | </div>
                  <NavLink to="/terms" className="webflow-link w-inline-block">
                    <div className="paragraph-tiny">TERMS &amp; CONDITIONS</div>
                  </NavLink>
                </Links>
                <div className="footer-links">
                  <a href="https://opensea.io/collection/tyco-swagg-unchained" target="_blank" className="footer-item-os"></a>
                  <a href="https://discord.gg/c7Mf9ehc8T" target="_blank" className="footer-item-discord"></a>
                  <a href="https://twitter.com/tycoswagg" target="_blank" className="footer-item"></a>
                  <a href="https://www.instagram.com/tycoswagg" target="_blank" className="footer-item-insta"></a>
                </div>
            </LinkDiv>
          </div>
        </div>
  );
}


const LogoImage = styled.img`
  width: 50%;
  height: auto;
`;

const CommunityLogo = styled.img`
  width: 50%;
  height: auto;
`;


const Links = styled.div`
display: flex;
flex-direction: row;
padding-bottom: 10px;

`;

const LinkDiv = styled.div`
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  flex-direction: column;

`;
