import React from 'react'
import styled from 'styled-components';
import { Modal, BasicModalProps } from './modal/Modal';
import metamaskLogo1x from '../assets/images/icons/metamask@1x.png'
import metamaskLogo2x from '../assets/images/icons/metamask@2x.png'
import walletConnectLogo1x from '../assets/images/icons/walletConnect@1x.png'
import walletConnectLogo2x from '../assets/images/icons/walletConnect@2x.png'
import coinbaseLogo1x from '../assets/images/icons/coinbase@1x.png'
import coinbaseLogo2x from '../assets/images/icons/coinbase@2x.png'
import fortmaticLogo1x from '../assets/images/icons/fortmatic@1x.png'
import fortmaticLogo2x from '../assets/images/icons/fortmatic@2x.png'
import { ModalText } from './modal/ModalComponents';
import  Wallet  from '../web3/index'

interface ConnectModalProps extends BasicModalProps {
  onConnectCallback: () => void;
}

export const ConnectModal = ({isVisible, onConnectCallback, onClose}: ConnectModalProps) => {


  const onWalletConnect = async (prov: any) => {
    try{
      Wallet.onWeb3Connect(prov).then(res => {
        Wallet.state.initiated = res
        onConnectCallback && onConnectCallback()
      })
    } catch(e) {

    }
  }

  return (
    <ModalView
      isVisible={isVisible}
      onClose={onClose}
      title="Connect"
    >
      <List>
        <ListItem>
          <ConnectButton onClick={() => onWalletConnect('injected')}>
            Metamask
            <Logo src={metamaskLogo1x} srcSet={metamaskLogo2x} alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onWalletConnect('walletconnect')}>
            WalletConnect
            <Logo src={walletConnectLogo1x} srcSet={walletConnectLogo2x} alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onWalletConnect('coinbasewallet')}>
            Coinbase Wallet
            <Logo src={coinbaseLogo1x} srcSet={`${coinbaseLogo2x} 2x`} alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onWalletConnect('fortmatic')}>
            Fortmatic
            <Logo src={fortmaticLogo1x} srcSet={fortmaticLogo2x} alt="logo" />
          </ConnectButton>
        </ListItem>
      </List>
    </ModalView>
  )
}

const ModalView = styled(Modal)`
  max-width: 400px;
`;

const Subtitle = styled(ModalText)`
  text-align: center;
`;

const List = styled.ul`

  @media (max-width: 600px) {
    margin-top: 16px;
  }
`;

const ListItem = styled.li`
  & + & {
    margin-top: 16px;
  }
`;

const ConnectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  padding: 15px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
  cursor: pointer;
  &:hover {
    outline: none;
    border: 1px solid #E7DDA0;
  }
`;

const Logo = styled.img`
  width: 28px;
  height: 28px;
`;
