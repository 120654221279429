import styled from "styled-components";
import { Text } from "../text/Text";

export const Section = styled.div`
  padding: 16px 24px;
  background: #101833;
  border-radius: 12px;

  border: 1px solid rgba(84,190,249, 0.1);
  :hover {
    border: 1px solid rgba(84,190,249, 0.5);
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

export const SectionWithBorder = styled(Section)`
  position: relative;
  border: 1px solid #35415A;
`;

export const SectionText = styled(Text)`
  font-size: 10px;
  color: #FFFFFF;
`;

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
`;
