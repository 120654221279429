import React, {useState, useContext, ReactNode} from 'react'

interface ContextProps {
  connection: [boolean, (status: boolean) => void],
  layerStatus: [boolean, (status: boolean) => void],
  noise: [boolean, (status: boolean) => void],
}

export const ConnectionContext = React.createContext<ContextProps>({
  connection: [false, (status: boolean) => {}],
  layerStatus: [false, (status: boolean) => {}],
  noise: [true, (status: boolean) => {}]
});

interface ConnectionProviderProps {
  children: ReactNode
}

export const ConnectionProvider = ({children}: ConnectionProviderProps) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isLayer2, setIsLayer2] = useState(false);
  const [isNoise, setIsNoise] = useState(true);

  return (
    <ConnectionContext.Provider value={{
      connection: [isConnected, setIsConnected],
      layerStatus: [isConnected, setIsConnected],
      noise: [isNoise, setIsNoise]
    }}>
      {children}
    </ConnectionContext.Provider>
  )
}

export const useConnection = () => useContext(ConnectionContext);
