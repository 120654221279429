import React, { ReactNode, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom';
import styled, {keyframes} from 'styled-components';
import { useConnection } from '../../pages/Connect/ConnectionProvider';
import { CloseButton } from '../buttons/CloseButton';
import { TextSemiBold } from '../text/Text';

export interface BasicModalProps {
  isVisible: boolean;
  onClose: () => void;
  className?: string;
}
export interface ModalProps extends BasicModalProps {
  title: string;
  children: ReactNode;
}

export const Modal = ({isVisible, onClose, children, title, className}: ModalProps) => {
  const {noise} = useConnection();
  const [isNoise] = noise;

  const listenKeyboard = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('keydown', listenKeyboard, true);
      return () => {
        window.removeEventListener('keydown', listenKeyboard, true);
      };
    }
  }, [isVisible, listenKeyboard]);

  if (!isVisible) return null

  return createPortal(
    <ModalView>
      {!isNoise && <ModalOverlay onClick={onClose}/>}
      <ModalBody className={className}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButtonWrapper>
            <CloseButton onClick={onClose}/>
          </CloseButtonWrapper>
        </ModalHeader>
        {children}
      </ModalBody>
    </ModalView>,
    document.body
  );
}

const fadeIn = keyframes`
  0%{opacity: 0}
  25%{opacity: 0.25}
  50%{opacity: 0.5}
  50%{opacity: 0.75}
  100%{opacity: 1}
`;

const ModalView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow-y: hidden;
  animation-duration: 0.35s;
  animation-name: ${fadeIn};
`;

const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 465px;
  max-height: 656px;
  width: 95%;
  transform: translate(-50%, -50%);
  padding: 16px 24px 24px;
  background: #2C2C2C;
  border-radius: 8px;
  overflow-y: auto;

  @media (max-height: 656px) {
    max-height: 95%;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 17, 38, 0.6);
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px 32px;
`;

const ModalTitle = styled(TextSemiBold)`
  text-align: center;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 24px;
`;
