import React, {useState} from 'react'
import { Screen } from '../../common/layout/Screen'
import { ButtonPrimaryMedium } from '../../common/buttons/Button';
import BigNumber from "bignumber.js";

import mintBg from "../../assets/mintgb.jpg";
import mintbanner from "../../assets/mintbanner.png";

import styled from 'styled-components'
import  Wallet  from '../../web3/index'

export const MintScreen = () => {

  const [mintAmount, setMintAmount] = useState('1');
  const [price, setPrice] = useState('0');
  const [txSending, setTxSending] = useState(false);
  const [amountLeft, setAmountLeft] = useState('0');
  const [isSaleActive, setIsSaleActive] = useState(false);
  const [isPresaleActive, setIsPresaleActive] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [presaleMints, setPresaleMints] = useState('0');



  const mint = async () => {
    if(txSending == false && isSaleActive == true){
        await Wallet.mint(mintAmount)
    }
  }

  const mintPresale = async () => {
    if(txSending == false && isPresaleActive == true && isWhitelisted == true){
        await Wallet.mintPresale(mintAmount)
    }
  }

  const getMintButtonText = () => {
    if(txSending == true)
      return 'Sending...'

    if(isSaleActive == false && isPresaleActive == false)
      return 'Sale Not Live'
    else if(new BigNumber(amountLeft).lte(0))
      return  "Sold Out!"
    else if(isSaleActive == true)
      return  "Mint"
    else if(isPresaleActive == true && isWhitelisted == true && new BigNumber(presaleMints).plus(mintAmount).gt(2))
      return  "Minted Max"
    else if(isPresaleActive == true && isWhitelisted == true)
      return  "Mint Presale"
    else if(isPresaleActive == true && isWhitelisted == false)
      return "Not on Mintlist"
  }

  const isEligible = () => {
    if(txSending == true)
      return false

    if(isSaleActive == false && isPresaleActive == false)
      return false
    else if(new BigNumber(amountLeft).lte(0))
      return  false
    else if(isSaleActive == true)
      return  true
    else if(isPresaleActive == true && isWhitelisted == true && new BigNumber(presaleMints).plus(mintAmount).gt(2))
      return  false
    else if(isPresaleActive == true && isWhitelisted == true)
      return  true
    else if(isPresaleActive == true && isWhitelisted == false)
      return false
  }


  const increaseMint = () => {
    if((Number(mintAmount) + 1) <= 2)
      setMintAmount(String(Number(mintAmount) + 1))

  }

  const decreaseMint = () => {
    if((Number(mintAmount) - 1) >= 1)
      setMintAmount(String(Number(mintAmount) - 1))

  }

  const mintTyco = async () => {
    console.log(presaleMints)
    console.log(mintAmount)

    console.log(new BigNumber(presaleMints).plus(mintAmount).toFixed())
    if((isSaleActive == true || isPresaleActive == true) && new BigNumber(amountLeft).gt(0)){
      if(isSaleActive == true){
        await Wallet.mint(mintAmount)
      } else if (isWhitelisted == true && new BigNumber(presaleMints).plus(mintAmount).lte(2)){
        await Wallet.mintPresale(mintAmount)
      }
    }
  }


  React.useEffect(() => {
    const interval = setInterval(() => {
      if(Wallet.state.web3 != null){
        setPrice(new BigNumber(Wallet.state.price).div(Math.pow(10,18)).toFixed())
        setAmountLeft(String(7734 - Number(Wallet.state.totalSupply)))
        setIsSaleActive(Wallet.state.isSaleActive)
        setIsPresaleActive(Wallet.state.isPresaleActive)
        setIsWhitelisted(Wallet.state.isWhitelisted)
        setPresaleMints(Wallet.state.presaleMints)
        setTxSending(Wallet.state.txSending != null)
      }
    }, 1000);

    return () => {}
  }, []);

  return (
    <Screen>
      <BackgroundImage>


      <OneBody>
        <PageImage src={mintbanner} />
      </OneBody>

      <OneBody>
          <MintSection>

          <AllStatus>
          <StatusBox>
          <StatusText>
          Mint status: Sold out
          </StatusText>


          </StatusBox>

          </AllStatus>

          <DefaultText>
          {amountLeft} / 7734 available
          </DefaultText>
          <DefaultText>
          </DefaultText>
          <MintAmountSection>
          <MintAmountButton onClick={decreaseMint}>
          -
          </MintAmountButton>
            <MintAmountText>
            {mintAmount}
            </MintAmountText>
            <MintAmountButton onClick={increaseMint}>
            +
            </MintAmountButton>
          </MintAmountSection>
          <MintButton disabled={!isEligible()} onClick={mintTyco}>
          {getMintButtonText()}
          </MintButton>

        </MintSection>
      </OneBody>


      </BackgroundImage>
    </Screen>
  )
}


export const BackgroundImage = styled.div`
  background-image: url(${mintBg});

  box-shadow: inset 0 0 30px rgba(245,221,81,1);
  box-shadow:
      inset 0px 21px 8px -10px rgba(245,221,81,1),
      inset 0px -21px 8px -10px rgba(245,221,81,1);

  width: 100%;
  min-height: 100vh;
  background-size: cover;
`;

export const OneBody = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 790px) {
    padding: 0px;
  }
`;

export const PageImage = styled.img`
  width: 35%;
  height: auto;
  padding-bottom: 30px;
  padding-top: 30px;
  margin-right: 15vw;

  @media (max-width: 900px) {
    width: 55%;
  }
`;

export const MintSection = styled.div`
  width: 50%;
  height: auto;
  padding-bottom: 50px;
  padding-top: 20px;

  justify-content: center;
  align-items: center;

  background-color: rgb(82,82,82);
  border: 2px black solid;
  border-radius: 5px;
  @media (max-width: 900px) {
    width: 80%;
  }
`;


const MintButton = styled(ButtonPrimaryMedium)`
  width: 128px;
  background-color: rgba(43,22,21,1);
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
`;

export const DefaultText = styled.p`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  color: rgba(255,255,255,0.75);
  font-weight: 800;
  font-size: 18px;
  width: 40%;
  text-align: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 80%;
  }
`;


export const DefaultTextTotal = styled.p`
display: flex;
flex-direction: row;
margin-left: auto;
margin-right: auto;
padding-top: 20px;
color: rgba(255,255,255,0.75);
font-weight: 500;
font-size: 13px;
width: 40%;
text-align: center;
justify-content: center;
@media (max-width: 900px) {
  width: 80%;
}

`;

const MintAmountSection = styled.div`
display: flex;
flex-direction: row;
margin-left: auto;
margin-right: auto;
text-align: center;
align-items: center;
padding-bottom: 50px;

justify-content: center;
`

export const MintAmountText = styled.p`
  display: flex;
  flex-direction: row;

  color: rgba(255,255,255,0.75);
  font-weight: 800;
  font-size: 40px;
  width: 40%;
  text-align: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 80%;
  }
`;

export const MintAmountButton = styled.button`
  display: flex;
  flex-direction: row;
  color: rgba(255,255,255,0.75);
  font-weight: 800;
  font-size: 30px;
  text-align: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 80%;
  }

  :hover {
    color: rgba(245,221,81,1);
  }
  ::hover {
    color: rgba(245,221,81,1);
  }
`;

const OnlineIndicator = styled.div`
    width: 8px;
    height: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: rgb(39, 174, 96);
    transition: background-color 250ms ease 0s;
`

const OfflineIndicator = styled.div`
    width: 8px;
    height: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: rgb(209, 56, 43);
    transition: background-color 250ms ease 0s;
`

const StatusBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

const AllStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: space-around;
    justify-content: space-around;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 25px;

`

export const StatusText = styled.p`
display: flex;
color: rgba(255,255,255,0.75);
font-weight: 500;
font-size: 13px;
text-align: center;
justify-content: center;
padding-right: 5px;

`;
