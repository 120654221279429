import React, { useState } from 'react';
import axios from 'axios'
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import BigNumber from "bignumber.js";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

import TYCO from './contracts/tyco.json';
import WL_ADDRESSES from './addresses.json';

import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import * as ethers from "ethers";

const keccak256 = require('keccak256')
const { MerkleTree } = require('merkletreejs')
const approve_amount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
const min_approve_amount = new BigNumber(approve_amount).div(2);

class Wallet {
    state = {
      web3: null as any,
      account: '0x000000000000000000000000000000000',
      txSending: null,
      tokensOfOwner: [] as Array<any>,
      isPresaleActive: false,
      isSaleActive: false,
      isWhitelisted: false,
      totalSupply: '1009',
      firstLoad: false,
      initiated: false,
      price: '0',
      presaleMints: '0'
    }

    constructor(){
      this.disconnect()

      const interval = setInterval(() => {
        if(this.state.web3)
          this.onChangeData()
      }, 15000);


      var timer = setInterval(async () => {
        if(this.state.txSending){
          let transactionReceipt = await this.state.web3.eth.getTransactionReceipt(this.state.txSending)
          if(transactionReceipt != null){
            this.state.txSending = null
            this.onChangeData()
          }
        }
      }, 5000)
    }



    disconnect() {
      this.state = {
        web3: null as any,
        account: '0x000000000000000000000000000000000',
        txSending: null,
        tokensOfOwner: [] as Array<any>,
        isPresaleActive: false,
        isSaleActive: false,
        isWhitelisted: false,
        totalSupply: '1009',
        firstLoad: false,
        initiated: false,
        price: '0',
        presaleMints: '0'
      }

    }



    async onWeb3Connect (prov) {
      try{
        const providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: { infuraId: "af28c7f14d2844af9bec9891c2692049" },
          },
          coinbasewallet: {
            package: CoinbaseWalletSDK, // Required
            options: {
              appName: "Tyco Swagg", // Required
              infuraId: "af28c7f14d2844af9bec9891c2692049", // Required
            }
          }
        };

        const web3Modal = new Web3Modal({
          network: "mainnet",
          cacheProvider: false,
          providerOptions,
        });

        var provider = await web3Modal.connectTo(prov);
        //const provider = new Web3Provider(window.ethereum);
        var web3 = new Web3(provider);

         provider = await web3Modal.connectTo(prov);
         web3 = new Web3(provider);
         window.web3 = web3

         this.state.account = (await web3.eth.getAccounts())[0];
         this.state.web3 = web3;


         this.onChangeData();
         return true
      } catch (e) {
        this.disconnect()
        console.log(e)
        return false
      }

    };

    getProof(){
      var elements = WL_ADDRESSES.map(v => keccak256(v))
      var merkleTree = new MerkleTree(elements, keccak256, { sort: true })
      return merkleTree.getHexProof(keccak256(this.state.account))
    }

    async mint(amount){
      if(this.state.txSending)
        return

      try{
        var ethAmount = new BigNumber(this.state.price).times(amount).toFixed()
        var tyco = new this.state.web3.eth.Contract(TYCO, '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e')
        var res = await tyco.methods.mint(amount).send({from: this.state.account, value: ethAmount})

        this.state.txSending = res.transactionHash
      } catch (e) {}

    }


    async mintPresale(amount){
      if(this.state.txSending)
        return

      try{
        var ethAmount = new BigNumber(this.state.price).times(amount).toFixed()
        var tyco = new this.state.web3.eth.Contract(TYCO, '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e')
        var res = await tyco.methods.mintPresale(amount, this.getProof()).send({from: this.state.account, value: ethAmount})
        this.state.txSending = res.transactionHash
      } catch (e) {}

    }


    async onChangeData () {
      if(this.state.web3){
        var tyco = new this.state.web3.eth.Contract(TYCO, '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e')

        try{
            var isWhitelisted = await tyco.methods.isWhitelisted(this.getProof()).call({from: this.state.account})

            const multicall = new Multicall({ web3Instance: this.state.web3, tryAggregate: true});
            const contractCallContext: ContractCallContext[] = []
            contractCallContext.push({
                reference: 'tokensOfOwner',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'tokensOfOwner', methodName: 'tokensOfOwner', methodParameters: [this.state.account]}]
            })
            contractCallContext.push({
                reference: 'isWhitelisted',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'isWhitelisted', methodName: 'isWhitelisted', methodParameters: [this.getProof()]}]
            })
            contractCallContext.push({
                reference: 'isPresaleActive',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'isPresaleActive', methodName: 'isPresaleActive', methodParameters: []}]
            })
            contractCallContext.push({
                reference: 'isSaleActive',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'isSaleActive', methodName: 'isSaleActive', methodParameters: []}]
            })
            contractCallContext.push({
                reference: 'totalSupply',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'totalSupply', methodName: 'totalSupply', methodParameters: []}]
            })
            contractCallContext.push({
                reference: 'PRICE',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'PRICE', methodName: 'PRICE', methodParameters: []}]
            })
            contractCallContext.push({
                reference: 'presaleWhitelistMints',
                contractAddress: '0x17e8429cc6785f5c1597e710dfb748c7bee1c21e',
                abi: TYCO,
                calls: [{reference: 'presaleWhitelistMints', methodName: 'presaleWhitelistMints', methodParameters: [this.state.account]}]
            })


            const results: ContractCallResults = await multicall.call(contractCallContext);

            var tokensOfOwner = results.results['tokensOfOwner'].callsReturnContext[0].returnValues
            var isPresaleActive = results.results['isPresaleActive'].callsReturnContext[0].returnValues[0]
            var isSaleActive = results.results['isSaleActive'].callsReturnContext[0].returnValues[0]
            var totalSupply = new BigNumber(results.results['totalSupply'].callsReturnContext[0].returnValues[0].hex)
            var price = new BigNumber(results.results['PRICE'].callsReturnContext[0].returnValues[0].hex)
            var presaleMints = new BigNumber(results.results['presaleWhitelistMints'].callsReturnContext[0].returnValues[0])

            var tokensOwned: any[] = [];
            for(let i in tokensOfOwner){
              tokensOwned.push(new BigNumber(tokensOfOwner[i].hex).toFixed())
            }

            this.state.tokensOfOwner = tokensOwned
            this.state.totalSupply = totalSupply.toFixed()
            this.state.isWhitelisted = isWhitelisted
            this.state.isPresaleActive = isPresaleActive
            this.state.isSaleActive = isSaleActive
            this.state.price = price.toFixed()
            this.state.presaleMints = presaleMints.toFixed()
        } catch (e) {
          console.log(e)
        }

      }
    };





}

export default (new Wallet())
