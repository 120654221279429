import React from 'react'
import styled from "styled-components";
import { ButtonPrimaryLarge } from "../buttons/Button";
import { SectionWithBorder } from "../section/Section";
import externalLinkIcon from '../../assets/images/icons/external-link.svg'

export const ModalButton = styled(ButtonPrimaryLarge)`
  margin-top: 32px;
`;

export const ModalText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #777F99;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 46px 0 32px;
`;

export const ModalDescription = styled(ModalText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 72px;
  text-align: center;
`;

export const ModalSection = styled(SectionWithBorder)`
  display: flex;
  justify-content: space-around;
  padding: 28px 24px;
`;

interface EtherscanLinkProps {
  href: string;
}

export const EtherscanLink = ({href}: EtherscanLinkProps) => (
  <Link href={href} target="_blank" rel="noopener noreferrer">
    View on zkScan
    <Icon src={externalLinkIcon} alt="external link" />
  </Link>
)

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #00C0FF;
`;


const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;
