import React, {useState} from 'react'
import { Screen } from '../../common/layout/Screen'
import { ButtonSecondaryMedium, ButtonSecondaryLarge } from '../../common/buttons/Button';
import { useConnection } from '../Connect/ConnectionProvider';

import stakingText from '../../assets/images/Title_Staking.png'
import stakingImage from '../../assets/images/Header_Staking.png'
import loadingImage from '../../assets/images/loading.gif'
import logo from "../../assets/1x/1ST SECTION/Asset 2.png";
import secondImage from "../../assets/1x/2ND SECT/Asset 11.png";
import secondText from "../../assets/1x/2ND SECT/Asset 9.png";
import thirdImage from "../../assets/1x/3RD SECT/Asset 12.png";
import raceImage from "../../assets/1x/4TH SECT/Asset_race.png";
import fourthImage from "../../assets/1x/4TH SECT/Asset 14.png";

import rarityImage from "../../assets/1x/5TH SECT/Asset 15.png";

import rarityOne from "../../assets/1x/5TH SECT/Asset 16.png";
import rarityTwo from "../../assets/1x/5TH SECT/Asset 17.png";
import rarityThree from "../../assets/1x/5TH SECT/Asset 18.png";
import rarityFour from "../../assets/1x/5TH SECT/Asset 19.png";

import sixImage from "../../assets/1x/6TH/Asset 20.png";
import sixImageOne from "../../assets/1x/6TH/Asset 21.png";
import sixImageTwo from "../../assets/1x/6TH/Asset 22.png";
import sixImageThree from "../../assets/1x/6TH/Asset 23.png";
import sixImageFour from "../../assets/1x/6TH/Asset 24.png";
import orangeTwitter from "../../assets/1x/6TH/Asset 25.png";
import orangeInsta from "../../assets/1x/6TH/Asset 26.png";

import roadmapImage from "../../assets/1x/7TH/roadmap_image.png";
import roadmap from "../../assets/1x/7TH/roadmap.png";

import twentyPer from "../../assets/1x/7TH/Asset 32.png";
import fourtyPer from "../../assets/1x/7TH/Asset 31.png";
import sixtyPer from "../../assets/1x/7TH/Asset 30.png";
import eightyPer from "../../assets/1x/7TH/Asset 29.png";
import hundredPer from "../../assets/1x/7TH/Asset 28.png";

import collabs from "../../assets/1x/8TH/Asset 33.png";
import darkTwitter from "../../assets/1x/8TH/Asset 44.png";
import darkInsta from "../../assets/1x/8TH/Asset 45.png";

import faqImage from "../../assets/1x/9TH/Asset_46.png";
import faqIcon from "../../assets/1x/9TH/Asset_47.png";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components'
import  Wallet  from '../../web3/index'

export const NewHomeScreen = () => {

  return (
    <Screen>
    <MainBody>

      <OneBody>
        <PageImage src={logo} />
          <DefaultText>
          Tyco Swagg is a collection of 7,734 hand drawn NFTs vacationing on the Ethereum blockchain.
          </DefaultText>
      </OneBody>

      <TwoBody>
        <HalfPageImage src={secondImage} />

        <OneBody>
          <PageImage src={secondText} />
            <AccentText>
            Life in hell wasn’t much fun, so Tyco climbs to earth to go on a heaven-like vacation.
            </AccentText>
            <br/>
            <AccentText>
            Surprised by the beauties of the Caribbean, he leaves behind tortures in the underworld for to become a fashion urban icon. The tropical path he joins is full of surprises and unlockable content by roadmap activation.
            </AccentText>
            <br/>
            <AccentText>
            You want Tyco lifestyle. You want Tyco’s everlasting fun.
            </AccentText>
        </OneBody>
      </TwoBody>

      <OneBody id='about'>
        <PageImageFull src={thirdImage} />

      </OneBody>

      <TwoBodyModified>
          <OneBody>
            <PageImage src={fourthImage} />
              <AccentText>
              Tyco is feeling reborn on this vacation, eager to feel adrenaline he combines his passion for bikes and his need for speed, unlocking devilish races where you get to play and win prizes.
              </AccentText>
              <br/>
              <AccentText>
              Gathering stylish accessories, getting some SWAGG and collecting bloody fast bikes will boost your Tyco’s chances to win races.
              </AccentText>
              <br/>
              <AccentText>
              All items are placed on-chain and secured by smart contracts in such a way that you can keep or trade them.
              </AccentText>
          </OneBody>
          <HalfPageImage src={raceImage} style={{marginRight: '-50px'}} />
      </TwoBodyModified>

      <OneBody style={{paddingTop: '50px'}}>
        <PageImage src={rarityImage} style={{paddingBottom: '0px', maxHeight: '200px'}} />
      </OneBody>

      <OneBody>
          <AccentTextTwo>
          There are 7,734 unique hand drawn Almighty Tycos with different styles and features, all grouped into 4 selective main categories.
          Appearance is not the only differentiator among them, certain skills are key features that will be of eternal significance to participate in Tyco Races.
          </AccentTextTwo>
      </OneBody>

      <TwoBody>

      <RarityColumn>
        <HeaderText>
        CASUAL
        </HeaderText>
        <RarityImage src={rarityOne}/>

        <AccentTextTwo>
        WEARING BASIC ACCESSORIES
        </AccentTextTwo>
      </RarityColumn>
      <RarityColumn>
        <HeaderText>
        TRENDY
        </HeaderText>
        <RarityImage src={rarityTwo}/>

        <AccentTextTwo>
        TATTOOS + RANDOM ACCESSORIES
        </AccentTextTwo>
      </RarityColumn>
      <RarityColumn>
        <HeaderText>
        FUNKY
        </HeaderText>
        <RarityImage src={rarityThree}/>

        <AccentTextTwo>
        WEARS WHATEVER HE WANTS
        </AccentTextTwo>
      </RarityColumn>
      <RarityColumn>
        <HeaderText>
        URBAN ICON
        </HeaderText>
        <RarityImage src={rarityFour}/>

        <AccentTextTwo>
        ECCENTRIC + POWERS & SKILLS
        </AccentTextTwo>
      </RarityColumn>

      </TwoBody>


      <OneBodyTeam id='team'>
        <PageImage src={sixImage} />
        <TwoBodyTeam>
          <RarityColumn>
            <RarityImage src={sixImageOne}/>

            <HeaderText>
            FASH
            </HeaderText>
            <Row style={{justifyContent: 'center'}}>
              <HeighlightText>
              LEAD ARTIST
              </HeighlightText>
              <SocialButton onClick={() => window.open('https://twitter.com/soyfash?s=21', "_blank")}>
                <SocialImage src={orangeTwitter}/>
              </SocialButton>
              <SocialButton onClick={() => window.open('https://instagram.com/el_fash?utm_medium=copy_link', "_blank")}>
                <SocialImage src={orangeInsta}/>
              </SocialButton>
            </Row>

            <AccentTextBio>
            Fash unleashed his artistic career as independent illustrator years ago. He combines urban street style with the essence of hip-hop as a baseline to master his designs.
            </AccentTextBio>
          </RarityColumn>
          <RarityColumn>
            <RarityImage src={sixImageTwo}/>

            <HeaderText>
            DOGGIE 29
            </HeaderText>
            <Row style={{justifyContent: 'center'}}>
              <HeighlightText>
              BIZ DEV
              </HeighlightText>
              <SocialButton onClick={() => window.open('https://twitter.com/doggie29_?s=21', "_blank")}>
                <SocialImage src={orangeTwitter}/>
              </SocialButton>
            </Row>

            <AccentTextBio>
            Enthusiastic about marketing research and business analytics, Doggie29 is a celestial deal hunter with experience understanding trends in different markets. Here to set strategic partnerships with potential users and projects.
            </AccentTextBio>
          </RarityColumn>
          <RarityColumn>
            <RarityImage src={sixImageThree}/>

            <HeaderText>
            FRANKIE V
            </HeaderText>
            <Row style={{justifyContent: 'center'}}>
              <HeighlightText>
              UX AND DESIGN
              </HeighlightText>
              <SocialButton onClick={() => window.open('https://twitter.com/frankvirla ', "_blank")}>
                <SocialImage src={orangeTwitter}/>
              </SocialButton>
            </Row>

            <AccentTextBio>
            FrankieV has been redeemed for holding 15 years of experience compelling products and conducting workflow analysis. He works on visual design and information architecture to deliver engaging experiences.
            </AccentTextBio>
          </RarityColumn>
          <RarityColumn>
            <RarityImage src={sixImageFour}/>

            <HeaderText>
            GIACOMO
            </HeaderText>
            <HeighlightText>
            LEAD DEV
            </HeighlightText>
            <AccentTextBio>
            Web3 integration and 7 years of experience as backend developer, Giacomo sparks the smart contracts where Tyco lives. He’s been part of several projects and is ready to go for this new heavenly gaming challenge.
            </AccentTextBio>
          </RarityColumn>
        </TwoBodyTeam>
      </OneBodyTeam>



      <OneBody id='roadmap'>
      <HalfPageImage src={roadmap} style={{alignItems: 'center', justifyContent: 'center'}}/>
      </OneBody>
      <TwoBodyModified>
        <OneBody>
        <TwoBody>
          <OneBodyModified>
              <AccentTextBold>
              1. Private Discord Channel (100% Minted)
              </AccentTextBold>
              <br/>
              <AccentText>
              The gang club for holders only. Alpha calls, info and hang out.
              </AccentText>
          </OneBodyModified>
        </TwoBody>

        <TwoBody >
          <OneBodyModified>
              <AccentTextBold>
              2. Rarity Listing (200 ETH Vol.)
              </AccentTextBold>
              <br/>
              <AccentText>
              List Tyco con RarityTools!
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody >
        <OneBodyModified>
              <AccentTextBold>
              3. Original: Tyco Unchained (500 ETH Vol.)
              </AccentTextBold>
              <br/>
              <AccentText>
              Tyco’s Comicbook: From hell to the Caribbean.
              All mints allocated to Tyco’s Holders.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody>
        <OneBodyModified>
              <AccentTextBold>
              4. Tyco Swagg (1K ETH Vol.)
              </AccentTextBold>
              <br/>
              <AccentText>
              Devil’s apparel with special pieces designed by a fashionist Tyco’s holders gain lifetime discounts.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody >
        <OneBodyModified>
              <AccentTextBold>
              5. Tyco Staking (2K ETH Vol.)
              </AccentTextBold>
              <br/>
              <AccentText>
              Tyco’s swagger for the fun stuff. Show off your evil side by getting some $SWAGG.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody >
        <OneBodyModified>
              <AccentTextBold>
              6. Live for Bikes (3K ETH Vol.)
              </AccentTextBold>
              <br/>
              <AccentText>
              Bikes become Tyco’s special treats.
              Bikes open for minting using $SWAGG.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody >
        <OneBodyModified>
              <AccentTextBold>
              7. Surprise Item / Roadmap 2.0 (5K ETH Vol.)
              </AccentTextBold>
              <br/>
              <AccentText>
              The good stuff.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        </OneBody>
        <HalfPageImage src={roadmapImage} style={{marginRight: '-50px'}} />
      </TwoBodyModified>


      <OneBody>
      <HalfPageImage src={collabs} style={{alignItems: 'center', justifyContent: 'center'}}/>
      </OneBody>

      <TwoBody>
        <OneBody>
            <AccentTextBold>
            MAR.C.O
            </AccentTextBold>
            <br/>
            <Row>
            <AccentText>
            MUSIC PRODUCTION
            </AccentText>
            <SocialButton onClick={() => window.open('https://instagram.com/mar.c.o?utm_medium=copy_link', "_blank")}>
              <SocialImage src={darkInsta}/>
            </SocialButton>
            </Row>
            <br/>
            <AccentText>
            Music producer and sound engineer from Berklee College of Music. Professional drums involved in songwriting, sound engineer and music production.
            Based in Los Angeles, CA.
            </AccentText>
        </OneBody>

        <OneBody>
            <AccentTextBold>
            RUBYCOBAIN
            </AccentTextBold>
            <br/>
            <Row>
            <AccentText>
            ARTIST
            </AccentText>
            <SocialButton onClick={() => window.open('https://twitter.com/rubbycobain', "_blank")}>
              <SocialImage src={darkTwitter}/>
            </SocialButton>
            <SocialButton onClick={() => window.open('https://instagram.com/rubbycobain?utm_medium=copy_link', "_blank")}>
              <SocialImage src={darkInsta}/>
            </SocialButton>
            </Row>
            <br/>
            <AccentText>
            AKA “The Money Maker”, RubyCobain is an eccentric-contemporary artist who has portrayed her work on actual currency bills as canvas, her perception of art as well as her pieces have wondered around the globe.
            </AccentText>
        </OneBody>

      </TwoBody>















      <OneBody id='faq'>
      <HalfPageImage src={faqIcon} style={{alignItems: 'center', justifyContent: 'center', maxHeight: '200px'}}/>
      </OneBody>
      <TwoBodyModified>
        <OneBody>
        <TwoBody>
          <OneBodyModified>
              <AccentTextBold>
              When is Tyco’s ComicBook?
              </AccentTextBold>
              <br/>
              <AccentText>
              Tyco’s ComicBook is the first roadmap item representing Tyco’s story. Minting will be possible for Tyco holders only. If available, minting will happen using $SWAGG.
              </AccentText>
          </OneBodyModified>
        </TwoBody>

        <TwoBody >
          <OneBodyModified>
              <AccentTextBold>
              How many Tycos will exist?
              </AccentTextBold>
              <br/>
              <AccentText>
              There will be only 7,734 unique Tycos with different features.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody >
        <OneBodyModified>
              <AccentTextBold>
              How do I mint a Tyco?
              </AccentTextBold>
              <br/>
              <AccentText>
              When Tyco’s minting is open, you can visit our site, connect your wallet, choose how many Tycos you’ll want to mint and approve your transaction.
              Requirements: ETH funds in a web3 wallet (e.g. Metamask) for gas.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody>
        <OneBodyModified>
              <AccentTextBold>
              How much will it cost to mint each piece?
              </AccentTextBold>
              <br/>
              <AccentText>
              The minting value of each Tyco will be FREE. Bear in mind that extra ETH should be held in your wallet to pay for gas.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        <TwoBody >
        <OneBodyModified>
              <AccentTextBold>
              How many Bikes will there be in total?
              </AccentTextBold>
              <br/>
              <AccentText>
              Each Tyco will be able to mint one Bike, so there will be a maximum on 7,734 bikes.
              </AccentText>
          </OneBodyModified>
        </TwoBody>
        </OneBody>
        <HalfPageImage src={faqImage} style={{marginRight: '-50px'}} />
      </TwoBodyModified>

    </MainBody>


    </Screen>
  )
}

export const FirstTabTitle = () => {
  return (
    <GridTitle>
    <h2>Hero</h2>
    <h2>Base Yield</h2>
    </GridTitle>
  )
}

export const SecondTabTitle = () => {
  return (
    <GridTitleSecond>
      <h2>Hero</h2>
      <h2>Base Yield</h2>
      <h2>Claimable</h2>
      <h2>Multiplier</h2>
    </GridTitleSecond>
  )
}


export const FirstTab = ({image, baseyield, click, active}) => {
  return (
    <Grid onClick={click} className={active == true ? 'active' : ''}>
    <GridImage src={image}/>
    <h2>{baseyield}</h2>
    </Grid>
  )
}

export const SecondTab = ({image, baseyield, claimable, multi, click, active}) => {
  return (
    <GridSecond onClick={click} className={active == true ? 'active' : ''}>
      <GridImage src={image}/>
      <h2>{baseyield}</h2>
      <h2>{claimable}</h2>
      <h2>{multi}</h2>
    </GridSecond>
  )
}



//        <PairsListWidget pairs={data.pairs}/>

export const MainBody = styled.div`
  @media (max-width: 790px) {
    padding: 0px;
  }
`;
export const Row = styled.div`
  display:flex;
  flex-direction: row;
  width: 80%;
`;

export const SocialButton = styled.button`
padding-left: 5px;
padding-right: 5px;
margin-bottom: auto;

`;
export const SocialImage = styled.img`
  width:25px;
  height: auto;
`;


export const STabs = styled(Tabs)`
  font-family: 'Merriweather';
  font-size: 18px;
  width: 100%;
`;

export const STabList = styled(TabList)`
  justify-content: center;
  margin: 0 0 10px;
  list-style-type: none;
  padding: 4px;
  display: flex;
`;

export const STab = styled(Tab)`
  width: 20%;
  height: 50px;
  padding: 4px;
  user-select: none;
  cursor: arrow;
  text-align: center;
  justify-content: center;
  font-family: 'Merriweather'
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  background: #373737;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;

  outline:none;
  &.is-selected {
    color: #373737;
    background: #FFFFFF;
    border-radius: 4px 0px 0px 4px;
  }

  &:focus {
    color: #373737;
    background: #FFFFFF;
    border-radius: 4px 0px 0px 4px;
    outline:none;
  }

  &:hover {
    color: #373737;
    background: #FFFFFF;
  }

  @media (max-width: 790px) {
    width: 40%;
  }

`;

export const SRTab = styled(STab)`
  border-radius: 0px 4px 4px 0px;

  &.is-selected {
    border-radius: 0px 4px 4px 0px;
  }

  &:focus {
    border-radius: 0px 4px 4px 0px;
  }
`;


export const ContentBody = styled.div`
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;

`;

export const STabPanel = styled(TabPanel)`
  display: none;
  height: auto;
  padding: 4px;
  margin-top: -5px;

  &.is-selected {
    display: block;
  }
`;

STabList.tabsRole = 'TabList';
STab.tabsRole = 'Tab';
STabPanel.tabsRole = 'TabPanel';

export const Grid = styled.div`
  font-family: 'Merriweather'
  font-weight: 400;
  display: grid;
  row-gap: 24px;
  column-gap: 30px;
  grid-template-columns: 1fr 3fr;
  height: 130px;
  padding: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    border: 1px #E7DDA0 solid;
  }

  @media (max-width: 900px) {
    row-gap: 24px;
    column-gap: 16px;
  }
  @media (max-width: 767px) {
    row-gap: 16px;
  }
`;

export const GridTitle = styled(Grid)`
  font-weight: 700;
  height: 50px;
  border-bottom: 3px white solid;
`;

export const GridSecond = styled(Grid)`
  grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
`;

export const GridTitleSecond = styled(GridSecond)`
  font-weight: 700;
  height: 50px;
  border-bottom: 3px white solid;
`;

export const LineSeperation = styled.div`
  width: 100%;
  height: 0px;
  opacity: 0.3;
  border: 1px solid #FFFFFF;
`;

export const GridImage = styled.img`
  width: auto;
  height: 110px;
`;

export const LoadingImage = styled.img`
  width: auto;
  height: 30px;
  padding-right: 15px;
`;

export const LoadingGrid = styled.div`
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Merriweather';
  font-weight: 700;
`;

export const DefaultText = styled.p`
  display: flex;
  flex-direction: row;
  color: rgba(43,22,21,1);
  font-weight: 500;
  font-size: 14px;
  width: 40%;
  text-align: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 80%;
  }
`;

export const AccentText = styled.p`
  display: flex;
  flex-direction: row;
  color: rgba(43,22,21,1);
  font-weight: 500;
  font-size: 14px;
  width: 80%;
  text-align: left;
`;

export const AccentTextBold = styled(AccentText)`
  font-weight: 700;
  font-size: 18px;
  width: 80%;
`;

export const AccentTextTwo = styled.p`
  display: flex;
  flex-direction: row;
  color: rgba(43,22,21,1);
  font-weight: 500;
  font-size: 14px;
  width: 80%;
  min-height: 100px;

  text-align: center;
  justify-content: center;
`;

export const AccentTextBio = styled(AccentTextTwo)`
  height: 275px;
  max-height: 275px;
  @media (max-width: 1250px) {
    height: auto;
  }
`;


export const HeaderText = styled.p`
  display: flex;
  flex-direction: row;
  color: rgba(43,22,21,1);
  font-weight: 700;
  font-size: 20px;
  width: 80%;
  text-align: center;
  justify-content: center;
`;

export const HeighlightText = styled.p`
  display: flex;
  flex-direction: row;
  color: rgba(251,117,19,1);
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 50px;
  text-align: center;
  justify-content: center;
`;

export const SecondaryText = styled.p`
  color: white;
  font-family: 'Merriweather';
  font-weight: 400;
  overflow:
`;

export const SelectAllText = styled.p`
  color: #7BE5C4;
  font-family: 'Merriweather';
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  margin-left: auto;
  max-width: fit-content;
  text-align: right;

  :hover {
    color: #E7DDA0;
  }
`;



export const TwoBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: space-between;
    flex-direction: column;
  }
  @media (max-width: 790px) {
    padding: 0;
  }
`;

export const TwoBodyModified = styled(TwoBody)`
    @media (max-width: 900px) {
      align-items: flex-end;
    }
`;

export const OneBody = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 790px) {
    padding: 0px;
  }
`;

const OneBodyTeam = styled.div`
  margin-top: 50px;
  margin-bottom: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 1260px !important) {
    justify-content: center;
    flex-direction: column;
  }
  @media (max-width: 790px) {
    padding: 0px;
  }
`;

const TwoBodyTeam = styled(TwoBody)`
  @media (max-width: 1260px) {
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const OneBodyModified = styled(OneBody)`
  flex-direction: flex-start;
  align-items: flex-start;

  margin-top: 0px;
  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;

  }
`;


export const PageTitle = styled.img`
  width: 200px;
  height: auto;
  padding-bottom: 30px;
`;

export const PageImage = styled.img`
  width: 60%;
  height: auto;
  padding-bottom: 30px;
`;

export const RarityImage = styled.img`
  padding-top: 30px;
  height: 250px;
  width: auto;
  padding-bottom: 30px;
`;

export const RarityColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 95%;
  }

`;

export const PageImageFull = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  padding-left: -50px;
`;

export const HalfPageImage = styled.img`
  width: 50%;
  height: auto;
`;
